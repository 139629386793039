.auth_1 {
  background: url('./polygonBtn-green-406x45.png');
  width: 100%;
  display: flex;
  height: 45px;
  padding: 11px;
  justify-content: center;
  align-items: center;
  background-position: center;
}
.auth_2 {
  background: url('./polygonBtn-softblack-188x45.png');
  width: 100%;
  height: 45px;
  padding: 11px;
  background-position: center;
  display: flex;
  align-items: center;
}

/* Cognisant Button */
.congnisant_button {
  position: relative;
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.congnisant_button:hover {
  opacity: 0.9;
}
.children {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* MindBio LSD Psychotherapy Button Group */
.mindbio {
  width: 88px;
  height: 33px;
}
.lsd {
  width: 56x;
  height: 33px;
}
.msPsychotherapy {
  width: 272px;
  height: 34px;
}
.btn_text {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

/* Tablet */
@media screen and (max-width: 1023px) {
  .mindbio {
    width: 73px;
    height: 30px;
  }
  .lsd {
    width: 45x;
    height: 30px;
  }
  .msPsychotherapy {
    width: 234px;
    height: 30px;
  }
  .btn_text {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
  }
}

/* Mobile */
@media screen and (max-width: 744px) {
  .mindbio {
    width: 67px;
    height: 21px;
  }
  .lsd {
    width: 45x;
    height: 21px;
  }
  .msPsychotherapy {
    width: 182px;
    height: 21px;
  }
  .btn_text {
    font-size: 10px;
  }
  .children {
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 745px) {
  .auth_1 {
    width: 324px;
  }
  .auth_2 {
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  .auth_1 {
    width: 295px;
  }
}
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
