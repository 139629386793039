.bannerImage {
  width: 100%;
  height: 643px;
  background-size: contain;
  background-position: center;
}
.navbar_container {
  position: fixed;
  margin: 0 -56px;
  width: 100%;
  z-index: 9999;
}
.headingMark {
  display: inline-block;
  padding: 0;
  margin: 0;
  line-height: 1.1;
}

.largeScreenGradiant {
  display: block;
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -10%;
  background: linear-gradient(
    270deg,
    rgba(8, 11, 22, 0.34) 0%,
    rgba(8, 11, 22, 1) 76% /* 100% opacity at 76% */
  );
  filter: blur(15px);
  z-index: 1;
}

.largeScreenBottomGradiant {
  display: block;
  width: 100%;
  height: 150px;
  position: absolute;
  bottom: -80px;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(23, 19, 33, 0.82) 0%,
    rgba(8, 11, 22, 1) 68%
  );
  filter: blur(15px);
}

@media screen and (max-width: 1280px) {
  .navbar_container {
    margin: 0 -48px;
  }
}

@media screen and (max-width: 1199px) {
  .navbar_container {
    margin: 0 -32px;
  }
}

@media screen and (max-width: 1024px) {
  .navbar_container {
    margin: 0 -16px;
  }
  .largeScreenGradiant {
    width: 70%;
    top: 0;
    left: -10%;
  }
}

@media only screen and (max-width: 744px) {
  .navbar_container {
    margin: 0;
  }
  .largeScreenGradiant {
    background: linear-gradient(
      180deg,
      rgba(8, 11, 22, 0.54) 0%,
      rgba(8, 11, 22, 0.7) 76% /* 100% opacity at 76% */
    );
    left: 0;
    top: 60%;
    width: 100%;
    height: 45%;
  }
  .largeScreenBottomGradiant {
    display: none;
  }
}
