.hamberger_body_out {
  position: absolute;
  top: -32px;
  right: -32px;
  width: 363px;
  /* padding: 1px; */
  z-index: 10000;
  border-radius: 20px;
  border: 1px solid #242832;
  background: transparent;
  clip-path: polygon(0% 0%, 98% 0%, 100% 3%, 100% 100%, 0% 100%);
}

.hamberger_body_in {
  /* width: 361px; */
  background: rgba(255, 255, 255, 0.02);
  padding: 24px 32px;
  border-radius: 20px;
  clip-path: polygon(0% 0%, 98% 0%, 100% 3%, 100% 100%, 0% 100%);
  backdrop-filter: blur(32px);
}
.menuLayout {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* safari only css */
@supports (-webkit-hyphens: none) {
  .hamberger_body_in {
    backdrop-filter: none;
    background-color: rgba(20, 20, 24, 0.95) !important;
  }
}

.hamberger_body_item {
  width: 186px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.hamberger_body_item_p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  line-height: 16px;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #ffffff;
  opacity: 0.5;
}

.hamberger_body_item_p_active {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;
  color: #ffffff;
}

@media screen and (max-width: 1023px) {
  .hamberger_body_out {
    position: absolute;
    top: -16px;
    right: -16px;
    width: 529px;
  }
  .hamberger_body_in {
    z-index: 10000;
    background: rgba(255, 255, 255, 0.02);
    padding: 24px 32px 32px 32px;
    border-radius: 20px;
    clip-path: polygon(0% 0%, 98% 0%, 100% 3%, 100% 100%, 0% 100%);
    backdrop-filter: blur(32px);
  }
  .menuLayout {
    flex-direction: row-reverse;
  }

  /* safari only css */
  @supports (-webkit-hyphens: none) {
    .hamberger_body_in {
      backdrop-filter: none;
      background-color: rgba(20, 20, 24, 0.95) !important;
    }
  }

  .hamberger_body_item_p {
    font-size: 16px;
  }

  .hamberger_body_item_p_active {
    font-size: 16px;
  }
}

@media screen and (max-width: 744px) {
  .hamberger_body_out {
    position: absolute;
    top: -16px;
    right: -16px;
    width: 375px;
    border-radius: 12px;
  }
  .hamberger_body_in {
    border-radius: 12px;
    padding: 24px;
  }
  .menuLayout {
    flex-direction: column-reverse;
  }
  .hamberger_body_item {
    padding: 16px 0;
  }
}
