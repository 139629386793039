/* Remove the body styles as they're already defined in globals.css */

.background-circle {
  position: absolute;
  border-radius: 50%;
  opacity: 0.12; /* 12% pass through */
  filter: blur(100px); /* Adjust blur amount as needed */
  z-index: -2; /* Place circles behind content */
}

/* Add this to ensure content is above the background circles */
.layout-content {
  position: relative;
  z-index: 1;
}

.circle-top-left {
  width: 50vw;
  height: 50vw;
  top: -25vw;
  left: -25vw;
  background: linear-gradient(45deg, #E93249, #8078B4);
}

.circle-mid-right {
  width: 40vw;
  height: 40vw;
  top: 20%;
  right: -20vw;
  background: linear-gradient(45deg, #E93249, #8078B4);
}

.circle-mid-left {
  width: 45vw;
  height: 45vw;
  top: 50%;
  left: -22.5vw;
  background: linear-gradient(45deg, #E93249, #8078B4);
}

.circle-bottom-right {
  width: 60vw;
  height: 60vw;
  bottom: -30vw;
  right: -30vw;
  background: linear-gradient(45deg, #E93249, #8078B4);
}
