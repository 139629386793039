@import './background.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  /* font-family: "__Lekton_364f7c", "__Lekton_Fallback_364f7c"; */
  font-family: Lekton;
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  background: #080b16;
  z-index: -2;
}

.cognisant-container {
  position: relative;
  box-sizing: border-box;
  background-image: url('../assets/img/background/hexabg.svg');
  background-size: cover;
  background-repeat: repeat;
  min-height: 100vh;
  color: white;
  margin: auto;
  padding: 42px 56px 48px;
  z-index: 1;
}
/* hides hexagon backgroun in Safari */
@supports (-webkit-hyphens: none) {
  .cognisant-container {
    position: relative;
    box-sizing: border-box;
    background-image: none;
    min-height: 100vh;
    color: white;
    margin: auto;
    padding: 42px 56px 48px;
    z-index: 1;
  }
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  border: unset;
}

.ant-picker {
  box-shadow: none;
  border: none;
}

.ant-table-row td:first-child {
  display: flex;
  padding: 25px !important;
}

::placeholder {
  color: #86878d !important;
}

.shineBg_body_top_left {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 500px;
  height: 500px;
  z-index: -1;
  background-image: url('../assets/img/shineBg.svg');
  background-size: contain;
  background-repeat: no-repeat;
}

.shineBg_body_bottom_right {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 30vw;
  height: 30vw;
  background-image: url('../assets/img/red-shadow-bottom-right.svg');
  background-size: cover;
  background-repeat: no-repeat;
}

.shineBg_body_bottom_center {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, 0%);
  background-image: url('../assets/img/red-shadow-bottom-center.svg');
  background-size: contain;
  background-repeat: no-repeat;
}

/* display: none in case of tablet and mobile */
@media only screen and (max-width: 768px) {
  .shineBg_body_bottom_right {
    display: none;
  }
}
/* display: none in case of tablet and mobile */

.shineBg_side_out {
  position: absolute;
  border-radius: 20px;
  top: 0px;
  left: 0px;
  width: 30vw;
  height: 30vw;
  background-image: url('../assets/img/shineBg.svg');
  background-size: cover;
  background-repeat: no-repeat;
}

.shineBg_side_in {
  position: absolute;
  border-radius: 20px;
  top: 0px;
  left: 0px;
  width: 546px;
  height: 546px;
  background-image: url('../assets/img/shineBg.svg');
  background-size: cover;
  background-repeat: no-repeat;
}

.gradient-circle {
  position: absolute;
  width: 546px;
  height: 546px;
  left: -255px;
  top: -172px;

  background: linear-gradient(133.79deg, #e93249 71.74%, #8078b4 100%);
  opacity: 0.15;
  filter: blur(104px);
}

.main-container {
  width: calc(100% - 262px);
}

.user-layout-out,
.main-body {
  background: rgba(255, 255, 255, 0.01) !important;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px) !important;
  border-radius: 20px;
  padding: 1px;
  width: 100%;
  position: relative;
  clip-path: polygon(
    0 0,
    calc(100% - 36px) 0,
    calc(100% - 34px) 0.5px,
    calc(100% - 32px) 1px,
    calc(100% - 30px) 2px,
    calc(100% - 2px) 30px,
    calc(100% - 1px) 32px,
    calc(100% - 0.5px) 34px,
    100% 36px,
    100% 100%,
    0 100%
  );
}

.panel-style-out {
  background: transparent !important;
  border-color: rgba(255, 255, 255, 0.1);
  border-width: 1px;
  backdrop-filter: blur(20px) !important;
  border-radius: 20px;
  padding: 1px;
  width: 100%;
  position: relative;
  clip-path: polygon(
    0 0,
    calc(100% - 36px) 0,
    calc(100% - 34px) 0.5px,
    calc(100% - 32px) 1px,
    calc(100% - 30px) 2px,
    calc(100% - 2px) 30px,
    calc(100% - 1px) 32px,
    calc(100% - 0.5px) 34px,
    100% 36px,
    100% 100%,
    0 100%
  );
}

.panel-style-out::before,
.user-layout-out::before,
.main-body::before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -2;
  border-radius: 20px;
  clip-path: polygon(
    0 0,
    calc(100% - 36px) 0,
    calc(100% - 34px) 0.5px,
    calc(100% - 32px) 1px,
    calc(100% - 30px) 2px,
    calc(100% - 2px) 30px,
    calc(100% - 1px) 32px,
    calc(100% - 0.5px) 34px,
    100% 36px,
    100% 100%,
    0 100%
  );
}
.panel-style-out::after,
.user-layout-out::after,
.main-body::after {
  content: '';
  position: absolute;
  width: 32px;
  height: 32px;
  top: 0;
  right: 0;
  background: linear-gradient(
      45deg,
      transparent 50%,
      rgba(255, 255, 255, 0.1) 50%,
      rgba(255, 255, 255, 0.1) 51%,
      transparent 51%
    ),
    transparent;
}

.panel-style-in,
.user-layout-in {
  padding: 31px;
  border-radius: 20px;
  overflow-x: visible;
}

@media only screen and (max-width: 576px) {
  .panel-style-in,
  .user-layout-in {
    padding: 23px;
  }
  .shineBg_body_top_left {
    width: 320px;
  }
  .panel-style-out::after {
    width: 20px;
    height: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .main-body {
    margin-top: 1rem;
  }
  .panel-style-out::after,
  .user-layout-out::after {
    width: 20px;
    height: 20px;
    top: -2px;
  }
}

.ant-layout-sider-trigger {
  background-color: unset !important;
}

.ant-menu,
.ant-menu-item {
  background-color: unset !important;
}

.ant-menu-submenu,
.ant-menu-item {
  margin-bottom: 25px !important;
}

.ant-layout {
  background-color: unset;
}

.ant-layout-sider-has-trigger {
  padding-bottom: 1px !important;
}

.ant-layout-sider-trigger {
  height: 100px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-layout-sider-children {
  min-height: 880px !important;
}

/* customization scroll bar */
::-webkit-scrollbar {
  width: 10px;
  position: absolute;
}

::-webkit-scrollbar-track {
  /* background: rgba(255, 255, 255, 0.1); */
  background: transparent;
  border: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border: 1px solid #252832;
  background: rgba(255, 255, 255, 0.04);
  border-radius: 5px;
  width: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.08);
}
/* customization scroll bar */

/* hexagon */
.r-hex {
  overflow: hidden;
  display: inline-block;
  width: 40px;
  height: 40px;
  transform: rotate(-30deg) skewX(30deg);
  border-radius: 0.5em;
}

.r-hex *,
.r-hex *:before {
  display: block;
  overflow: hidden;
  width: inherit;
  height: inherit;
  border-radius: inherit;
}

.r-hex-inner {
  transform: skewX(-30deg) rotate(60deg) skewX(30deg);
  transition: opacity 0.75s;
  cursor: pointer;
}

.r-hex:first-child .r-hex-inner:before,
.r-hex-inner-2 {
  transform: skewX(-30deg) rotate(60deg) skewX(30deg);
}

.r-hex-inner-2:before {
  transform: skewX(-30deg) rotate(-90deg);
  line-height: 40px;
  text-align: center;
}

.main-body {
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
}
.invisible-inner-body {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 60px;
}

.border-bottom {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  bottom: 1px;
}

.bottom_11 {
  bottom: 11px;
}

.margin_bottom_11 {
  margin-bottom: 11px;
}

.ant-modal-content {
  border-radius: 20px !important;
  border: 1px solid #302c35 !important;
  background: rgba(255, 255, 255, 0.02) !important;
  backdrop-filter: blur(32px) !important;
}

.ant-modal-mask {
  backdrop-filter: blur(6.5px);
}

/* safari only css */
@supports (-webkit-hyphens: none) {
  .ant-modal-mask {
    backdrop-filter: none;
    background-color: rgba(20, 20, 24, 0.3) !important;
    /* filter: blur(18px); */
  }

  .ant-modal-content {
    backdrop-filter: none !important;
    background-color: rgba(20, 20, 24, 0.95) !important;
    /* filter: blur(6px); */
  }
}

/* customize tabs */

.ant-tabs-nav-list {
  color: #86878d;
}

.ant-tabs-nav::before {
  border-bottom: 2px solid rgba(255, 255, 255, 0.1) !important;
}

.ant-tabs-ink-bar {
  border-bottom: solid #e93249;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: unset;
}

/* customize tabs */

/* customize highchart */
.highcharts-credits {
  display: none;
}

.highcharts-legend {
  display: none;
}
/* customize highchart */

/* .ant-switch .ant-switch-handle::before {
  background-color: #86878d;
} */

.modalStyle {
  color: red;
}

.modalStyle .ant-modal .ant-modal-content .ant-modal-header {
  border-radius: 20px 20px 0 0;
  background: transparent;
}

.modalStyle .ant-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  color: red !important;
}

:where(.css-dev-only-do-not-override-dugt3w).ant-tabs-right
  > .ant-tabs-content-holder,
:where(.css-dev-only-do-not-override-dugt3w).ant-tabs-right
  > div
  > .ant-tabs-content-holder {
  border-right: none;
}

/* :where(.css-dev-only-do-not-override-lu9nt4).ant-tabs-right
  > .ant-tabs-content-holder,
:where(.css-dev-only-do-not-override-lu9nt4).ant-tabs-right
  > div
  > .ant-tabs-content-holder {
  border-right: none;
} */
.ant-tabs-right > div > .ant-tabs-content-holder {
  border-right: none !important;
}

/* AntD Collapse Style Start */

.ant-collapse {
  border: none !important;
  color: #ffffff;
  border-radius: unset;
}
.ant-collapse > .ant-collapse-item {
  /* border-bottom: none; */
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.ant-collapse > .ant-collapse-item-active {
  border-bottom: none;
}

.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  > .ant-collapse-header-text {
  color: var(--white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 16px */
}
.ant-collapse .ant-collapse-content {
  background-color: transparent;
  border-top: none;
}
.ant-collapse > .ant-collapse-item:last-child,
:where(.css-dev-only-do-not-override-betz7h).ant-collapse
  > .ant-collapse-item:last-child
  > .ant-collapse-header {
  border-radius: unset;
}

/* AntD Collapse Style End */

/* Swipeable Slider Style Start */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content !important;
}

/* Swipeable Slider Style End */

/* Icon box shadow commonly used Start */

.orange_shadow {
  filter: drop-shadow(0px 0px 30px rgba(128, 157, 47, 0.4));
}

.cyan_shadow {
  filter: drop-shadow(0px 0px 30px rgba(53, 243, 255, 0.15));
}

.pink_shadow {
  filter: drop-shadow(0px 0px 30px rgba(157, 47, 153, 0.4));
}

.green_shadow {
  filter: drop-shadow(0px 0px 30px rgba(69, 157, 47, 0.4));
}

.purple_shadow {
  filter: drop-shadow(0px 0px 30px rgba(239, 53, 255, 0.4));
}

.white_shadow {
  filter: drop-shadow(0px 3.1314480304718018px 40px rgba(255, 255, 255, 0.5));
}
.red_shadow {
  filter: drop-shadow(
    0px 3.1314480304718018px 46.97172164916992px rgba(233, 50, 73, 0.5)
  );
}

/* Icon box shadow commonly used End */

/* ChatGpt Temperature Slider Style Start */
.horizontal-slider {
  width: 100%;
  /* max-width: 321px; */
  height: 22px;
}

.horizontal-slider .example-thumb {
  font-size: 0.9em;
  text-align: center;
  background-color: #e93249;
  color: white;
  cursor: pointer;
}

.horizontal-slider .example-thumb.active {
  outline: none;
}

.horizontal-slider .example-track {
  position: relative;
  background: rgba(255, 255, 255, 0.1);
}

.horizontal-slider .example-track.example-track-1 {
  background: rgba(255, 255, 255, 0.1);
}

.horizontal-slider .example-track.example-track-2 {
  background: #e93249;
}

.horizontal-slider .example-track {
  top: 11px;
  height: 1px;
}

.horizontal-slider .example-thumb {
  width: 8px;
  height: 22px;
  clip-path: polygon(
    50% 0%,
    100% 4px,
    100% calc(100% - 4px),
    50% 100%,
    0% calc(100% - 4px),
    0% 4px
  );
}

.double-slider {
  width: 100%;
  height: 36px;
}

.double-slider .example-thumb.active {
  outline: none;
}

.double-slider .example-track {
  position: relative;
  background: rgba(255, 255, 255, 0.1);
}

.double-slider .example-track.example-track-1 {
  background: rgba(255, 255, 255, 0.1);
}

.double-slider .example-track.example-track-2 {
  background: #e93249;
}

.double-slider .example-track {
  top: 30px;
  height: 1px;
}

/* ChatGpt Temperature Slider Style End */

/* Custom Text Color start */
.text_grey {
  color: #86878d;
}

/* Custom Text Color end */

/* Antd Switch style start*/
.ant-switch {
  min-width: 51px;
  height: 24px;
}
.ant-switch .ant-switch-handle {
  top: 3px;
  inset-inline-start: 3px;
}
.ant-switch.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 21px);
}
/* Antd Switch style end */

.ant-popover-arrow {
  display: none !important;
}

.ant-popover-inner {
  background: #131621 !important;
  padding: 20px 35px !important;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

@media screen and (max-width: 1280px) {
  .cognisant-container {
    padding: 42px 48px 48px;
  }
}

@media screen and (max-width: 1199px) {
  .cognisant-container {
    padding: 32px;
    background-image: none;
  }
}

/* Tablet */

.ant-divider-vertical {
  height: unset;
}

@media screen and (max-width: 1023px) {
  .cognisant-container {
    padding: 16px;
  }
  .panel-style-out,
  .user-layout-out {
    border-radius: 10px;
    clip-path: polygon(
      0% 0%,
      calc(100% - 20px) 0%,
      100% 12px,
      100% 100%,
      0% 100%
    );
  }

  .panel-style-in,
  .user-layout-in {
    border-radius: 10px;
    padding: 15px;
    clip-path: polygon(
      0% 0%,
      calc(100% - 20px) 0%,
      100% 12px,
      100% 100%,
      0% 100%
    );
  }

  /* customization scroll bar */

  /* Antd Switch style start*/
  .ant-switch {
    min-width: 40px;
    height: 18px;
  }
  .ant-switch .ant-switch-handle {
    top: 3px;
    inset-inline-start: 4px;
    width: 12px;
    height: 12px;
  }
  .ant-switch.ant-switch-checked .ant-switch-handle {
    inset-inline-start: calc(100% - 16px);
  }
  /* Antd Switch style end */
}
/* Mobile */

@media only screen and (max-width: 744px) {
  .cognisant-container {
    padding: 0px;
    padding-top: 12px;
  }
  .panel-style-out,
  .panel-style-in {
    clip-path: polygon(
      0% 0%,
      calc(100% - 20px) 0%,
      100% 20px,
      100% 100%,
      0% 100%
    );
  }
  .user-layout-out,
  .user-layout-in {
    padding: 0px;
    background: none;
    clip-path: none;
  }
}

@media only screen and (max-width: 745px) {
  .ant-table-row td:first-child {
    padding: 20px !important;
  }
}

.react-flow__attribution {
  display: none !important;
}

.node {
  /* fill: green; */
  stroke: lightgray;
  stroke-opacity: inherit;
  stroke-width: 2px;
}

.link {
  background-color: red;
  stroke: gray;
  stroke-opacity: inherit;
  stroke-width: 2px;
}

.link-label {
  font-family: 'Lekton';
  font-weight: 100;
  text-anchor: middle;
  stroke: transparent;
  fill: white;
}
